import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/Layout'
import BannerLanding from '../../components/BannerLanding'
import Facilities from '../../components/accommodations/Facilities';
import ImageGallery from '../../components/accommodations/ImageGallery'
import Description from '../../components/accommodations/Description'
import Agenda from '../../components/accommodations/Agenda'
import PriceTable from '../../components/accommodations/PriceTable'
import Related from '../../components/accommodations/Related'

import getFullData from '../../messages/bangalo-messages'

const Bangalo = () => {
    const bangaloData = getFullData()

    const [windowWidth, setWindowWidth] = useState(0)

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    };

    useEffect(() => {
        if (window) {
            setWindowWidth(window.innerWidth)
            window.addEventListener("resize", handleResize)
        }
    }, []);

    return (
        <Layout>
            <Helmet
                title="Bangalô do Sóter"
                meta={[
                    { name: 'description', content: 'Hospedagem na Lapinha da Serra - MG' },
                    { name: 'keywords', content: 'Lapinha da Serra, Lapinha, hospedagem, acomodação, acomodações, pousada, Serra do Cipó, Minas Gerais, turismo, viagem' },
                ]}
            />

            <BannerLanding backgroundImage={ windowWidth < 650 ? bangaloData.coverImage : bangaloData.coverImageWide} title={bangaloData.title}/>

            <div id="main">
                <Facilities facilities={bangaloData.facilities} />

                <Description description={bangaloData.description} infos={bangaloData.infos}/>
                
                <ImageGallery accommodation={bangaloData.id} />
                
                <Agenda accommodation={bangaloData.title} />

                <PriceTable accommodation={bangaloData}/>

                {
                    bangaloData.related.map(accommodation => <Related accommodation={accommodation}/>)
                }
            </div>
        </Layout>
    )
}

export default Bangalo